<template lang="pug">
transition(name="modal")
  div
    .modal-mask
      .modal-wrapper
        .modal-dialog(style="width: 520px;max-width: 95%;" role="document")
          .modal-content.rounded-2
            .modal-body.p-0
              loader(:loading="saving")
                .d-flex.justify-content-between.border-bottom.py-3.px-4.mt-1
                  h4.h5.m-0.text-dark.font-weight-bold Tambahkan Jenis Kendaraan
                  a.bg-white.border-none(
                    href="javascript:;"
                    role="button",
                    data-dismiss="modal",
                    aria-label="Close"
                    @click="$emit('close')"
                  )
                    div
                      img(:height="14" :width="14" src="@/assets/img/close.png")
                .py-4.my-2.px-4
                  b-form-group(label="Nama")
                    b-form-input(v-model="name" placeholder="Masukkan jenis kendaraan")
                  b-form-group(label="Wiper")
                    b-form-select(v-model="wiper_status" :options=[{
                      text: 'True',
                      value: 1
                    }, {
                      text: 'False',
                      value: 0
                    }]
                  )
                .py-3.px-4.d-flex.justify-content-end.align-items-center
                  button.border-0.bg-white.py-1.px-5.text-danger(style="text-transform: none;" @click="$emit('close')") Batal
                  button.px-4.btn-sm.btn.btn-primary(style="text-transform: none;" @click="onSave" :disabled="!name || wiper_status === null") Tambahkan
</template>

<script>
import { postAddVehicleType } from "@/utils/api";
export default {
  data() {
    return {
      saving: false,
      name: "",
      wiper_status: null,
    };
  },
  watch: {},
  components: {},
  props: ["close", "success"],
  computed: {},
  methods: {
    onSave() {
      postAddVehicleType({
        name: this.name,
        wiper_status: this.wiper_status,
      }).then(({ data: { result } }) => {
        this.$emit("success", result);
        this.$emit("close");
      });
    },
  },
  mounted() {},
};
</script>
