<template lang="pug">
div
  card(title='Vehicles')
    div.row
      .col-12
        .d-flex.justify-content-end.mb-5
          matador-search#search-box(placeholder='Cari' v-model="search" autocomplete='off')
          matador-button.ml-3(@click="clickAdd", icon="fa fa-plus")
            | Add Vehicles
        keep-alive
          .table-responsive(v-if="auth && auth.organization_id")
            bs-table(
              ref="table"
              :columns="table.columns",
              :options="table.options",
              :data="userList",
              @on-post-body="vueFormatterPostBody"
              @on-search="onEvent"
            )
  vehicle-modal(v-if="showVehicleDetail" :vehicle="vehicle" @close="showVehicleDetail = false" @success="")
  vehicle-form-modal(v-if="showVehicleAdd" :vehicle="vehicle" @close="showVehicleAdd = false" @success="onVehicleChanged")
  vehicle-form-modal(v-if="showVehicleEdit" :vehicle="vehicle" @close="showVehicleEdit = false" @success="onVehicleChanged")

  confirmation-modal(
    :vehicle="vehicle"
    v-if="deleteModal"
    @close="deleteModal = false" 
    @confirm="onConfirmDelete" 
    title="Hapus Kendaraan"
    confirm-text="Ya, Hapus"
  )        
    p.m-0
     | Apakah Anda yakin ingin menghapus kendaraan ini?

</template>
<script>
import tableMixin from "@/mixins/table";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import ChevronUp from "vue-material-design-icons/ChevronUp.vue";
import PlusIcon from "vue-material-design-icons/Plus.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline.vue";
import EyeOffOutline from "vue-material-design-icons/EyeOffOutline.vue";
import ConfirmationModal from "@/components/Matador/ConfirmationModal";
import VehicleFormModal from "@/components/Matador/VehicleFormModal";
import VehicleModal from "@/components/Matador/VehicleModal";
import Dropdown from "@/components/Dropdown.vue";
import { get, result } from "lodash";
import dayjs from "dayjs";

import {
  getDevice,
  getVehicleList,
  getVehicle,
  getVehicleByOrganizationId,
  postDeleteVehicle,
  getVehicleDetail,
} from "../../../utils/api";

export default {
  mixins: [tableMixin],

  components: {
    ChevronDown,
    ChevronUp,
    EyeOutline,
    EyeOffOutline,
    ConfirmationModal,
    PlusIcon,
    VehicleFormModal,
    VehicleModal,
    Dropdown,
  },

  data() {
    return {
      search: "",
      showVehicleAdd: false,
      showVehicleEdit: false,
      deleteModal: false,
      showVehicleDetail: false,
      vehicle: "",
      organizationList: [],
      position: "",
      currentUser: this.$store.state.auth.user_id,
      showAdd: false,
      userList: [],
      perPage: 10,
      table: {
        options: {
          ajax: this.getVehicle,
          search: true,
          pagination: true,
          showSearchButton: true,
          searchSelector: "#search-box",
          pageSize: this.perPage,
          sortable: true,
          sortDesc: true,
          paginationHAlign: "center",
          paginationDetailHAlign: "right",
          paginationParts: ["pageInfo", "pageList"],
          classes: ["table", "table-hover"],
          loadingFontSize: 16,
          sidePagination: "server",
        },
        columns: [
          {
            title: "",
            field: "",
            searchable: false,
            widthUnit: "%",
            width: "7",
            formatter: (value, row) => {
              return this.vueFormatter({
                template: `
                <div class="d-flex.align-items-center row" style="margin:10px 10px 0 ">
                    <img class="rounded" :height="55" :width="50" v-if="row.image" :src="row.image"></img>
                    <img class="rounded" :height="55" :width="50" v-if="!row.image" src="car.png"></img>
                </div>`,
                data: { row, value },
              });
            },
          },
          {
            title: "Vehicle Name",
            field: "vehicle_name",
            sortable: true,
            searchable: true,
            widthUnit: "%",
            width: "18",
          },
          {
            title: "VIN",
            field: "vin",
            sortable: true,
            searchable: true,
            widthUnit: "%",
            width: "18",
          },
          {
            field: "description",
            title: "Device Name",
            sortable: true,
            searchable: true,
            widthUnit: "%",
            width: "24",
          },
          {
            field: "series",
            title: "Series",
            sortable: true,
            searchable: true,
            widthUnit: "%",
            width: "18",
          },
          {
            field: "wiper_width",
            title: "Wiper WIdth",
            sortable: true,
            searchable: true,
            widthUnit: "%",
            width: "15",
          },
          {
            field: "",
            title: "",
            formatter: (value, row) => {
              return this.vueFormatter({
                template: `<div>
                  <dropdown tag="a" class="dropdown-toggle-no-caret" icon="mdi mdi-dots-vertical matador-primary-color h4">
                    <li class="p-3 matador-text-03" @click="onShow(row)"> Detail </li>
                    <li class="p-3 matador-text-03" v-on:click="onEdit(row)">Edit</li>
                    <li class="p-3 matador-text-03" v-on:click="onDelete(row)">Delete</li>
                  </dropdown>
                </div>`,
                data: { row, value },
                methods: {
                  onShow: this.clickDetails,
                  onEdit: this.clickEdit,
                  onDelete: this.clickDelete,
                },
                components: {
                  Dropdown,
                },
              });
            },
            sortable: false,
            searchable: false,
            widthUnit: "%",
            width: "8",
          },
        ],
      },
    };
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
    // tableOptions() {
    //   return {
    //       ajax: this.getVehicle,
    //       search: true,
    //       pagination: true,
    //       pageSize: this.perPage,
    //       searchSelector: "#search-box",
    //       showSearchButton: true,
    //       sortable: true,
    //       paginationHAlign: "center",
    //       paginationDetailHAlign: "right",
    //       paginationParts: [ "pageInfo", "pageList"],
    //       classes: ["table", "table-hover"],
    //     }
    // }
  },
  methods: {
    customSort(sortName, sortOrder, data) {
      var order = sortOrder === "desc" ? -1 : 1;
      data.sort(function (a, b) {
        var aa = +(a[sortName] + "").replace(/[^\d]/g, "");
        var bb = +(b[sortName] + "").replace(/[^\d]/g, "");
        if (aa < bb) {
          return order * -1;
        }
        if (aa > bb) {
          return order;
        }
        return 0;
      });
    },
    getVehicle(params) {
      const responseHandler = ({ data, status }) => {
        if (status !== 200) throw new Error("Failed to load list users");

        let response = data.result.data;

        response.map((item) => {
          item.vehicle_name = item.vehicle_name || item.unit_id;
          return item;
        });

        this.userList = response;
        let pagination = data.result.pagination;

        if (!this.userList || !this.userList.length)
          return params.success(false);
        let resource = {
          rows: this.userList,
          total: pagination.total_data || 0,
          totalNotFiltered: pagination.total_data || 0,
        };

        setTimeout(() => params.success(resource), 500);
      };

      let page = params.data.offset / params.data.limit || 0;
      let payload = {
        limit: params.data.limit || 10,
        page: page + 1,
        search: params.data.search,
      };

      getVehicle(payload)
        .then(responseHandler)
        .catch((e) => {
          let err = e.response ? e.response.data : e;
          this.$toast.error(err.message || "Failed to load user list");
        });
    },
    clickDetails(row) {
      this.showVehicleDetail = false;
      getVehicleDetail({
        unit_id: row.unit_id,
        trip: dayjs().format("YYYY-MM-DD"),
      }).then(({ data: { code, result, message } }) => {
        this.vehicle = result;
      });
      this.showVehicleDetail = true;
    },
    clickAdd() {
      this.showVehicleAdd = false;
      this.vehicle = null;
      this.showVehicleAdd = true;
    },
    clickEdit(row) {
      this.showVehicleEdit = false;
      this.vehicle = row;
      this.showVehicleEdit = true;
    },
    clickDelete(row) {
      this.deleteModal = false;
      this.vehicle = row;
      this.deleteModal = true;
    },
    onConfirmDelete() {
      this.deleteModal = false;
      postDeleteVehicle(this.vehicle.unit_id).then(
        ({ data: { result, code, message } }) => {
          if (code === 200) {
            this.$refs.table.refresh();
            this.$toast.open("The vehicle successfully deleted.");
          } else {
            this.$toast.error(message);
          }
        }
      );
    },
    onVehicleAdd() {
      // this.$toast.open('Successfully save vehicle data.');
    },
    onVehicleEdit() {
      // this.$toast.open('Successfully change vehicle data.');
    },
    onVehicleChanged(vehicle) {
      this.showVehicleAdd = false;
      this.showVehicleEdit = false;
      this.$refs.table.refresh();
      this.search = vehicle.vin;
    },
    onEvent(event) {},
  },
  watch: {
    pageSize: function (newVal) {
      this.$refs.table.refreshOptions();
    },
  },
  mounted() {
    // reset search if clear
    // var that = this;
    // window.$('.search input').on('search', function() {
    //   if(!window.$(this).val()) {
    //     that.$refs.table.resetSearch();
    //   }
    // });
  },
};
</script>
<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.header {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
}
.content-filter {
  margin-top: -1rem;
}
.tab-filter {
  min-width: 200px;
  border-right: 2px solid #eaeaec;
  padding-right: 0.5rem;
}
.badge-circle {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 4px;

  text-align: center;

  font: 10pt Arial, sans-serif;
  font: 10pt Arial, sans-serif;
}
.p-viewer {
  float: right;
  margin-right: 10px;
  margin-top: -33px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
</style>
