// import roleVehicles from '@/roles/roleVehicles'
// import roleVehicleDetail from '@/roles/roleVehicleDetail'

import DefaultPage from './Default/Page.vue'
import DetailPage from './VehicleDetailPage.vue'

import SatellitePage from './Satellite/Page.vue'
import SatellitePageList from './Satellite/MonitoringPage.vue'
import SatelliteDetail from './Satellite/DetailPage.vue'
import SatelliteReport from './Satellite/ReportPage.vue'
import SatelliteTable from './Satellite/TableListPage.vue'



const SatelliteRouter = () => {
  return {
    path: '/vehicle_satellites',
    name: 'vehicle_satellites',
    // beforeEnter: roleVehicles, 
    component: SatellitePage,
    children: [
      { path: '', component: SatelliteTable },
      { path: '/vehicle_satellites/follows', component: SatellitePageList },
      { path: '/vehicle_satellites/:vehicle_id', component: SatelliteDetail },
      { path: '/vehicle_satellites/:vehicle_id/report', component: SatelliteReport },
    ]
  }
}

const DefaultRouter = () => {
  return {
    path: '/vehicles',
    name: 'vehicles',
    // beforeEnter: roleVehicles,
    component: DefaultPage
  }
}

const DetailRouter = () => {
  return  {
    path: "dashboard/detail/:unit_id",
    name: "vehicle-detail",
    // beforeEnter: roleVehicleDetail,
    component: DetailPage
  }
}


export default {
  
  Default: DefaultRouter,
  Satellite: SatelliteRouter,
  Detail: DetailRouter
}
