<template lang="pug">
loader(:loading="!vehicle")
  .container-fluid(v-if="vehicle" @click="closeDatePicker")
    .row.mb-4
      .col-md-6.col-sm-12.d-flex
        .card.mb-4.flex-grow-1
          .card-body
            .row
              .col-12
                img.w-100.matador-border-radius(v-if="vehicle.image" :src="vehicle.image")
                img.w-100.matador-border-radius(v-if="!vehicle.image" src="@/assets/img/car-placeholder.png")
                h4.my-5 {{ vehicle.unit_id }}
                .row(v-for="item in vehicle_data").mb-3
                  .col-6.col-xl-4
                    label {{ item[0] }}
                  .col-6.col-xl-8
                    span {{ item[1] }}
      .col-md-6.col-sm-12.d-flex
        .card.mb-4.flex-grow-1
          .card-body
            .row
              .col-12
                .map
                  #mapDetail(style="min-height: 400px")
              .col-12.mt-5
                .border.p-3.matador-border-radius(style="min-height: 400px")
                  .d-flex.justify-content-center.align-items-center
                    a.text-secondary(href="javascript:;" role="button" @click="switchDate(globalDate, -1, 'globalDate')")
                      chevron-left-icon.icon-2x
                    span.mx-3 {{ globalDate | date }}
                    a.text-secondary(href="javascript:;" role="button" @click="switchDate(globalDate, 1, 'globalDate')")
                      chevron-right-icon.icon-2x
                  .d-flex.trip-container(style="max-height: 21rem")
                    .trip-timeline
                    if(trip)
                      section.timeline-section.bg-light.py-3.pr-3.pl-5.rounded.border.mt-3(v-for="item in [...trip]")
                        .d-flex
                          p.mb-0.mt-0.mr-auto.pr-3 {{ item.origin }}
                          span.text-muted {{ item.departure_time }}
                        .d-flex.align-items-center.pb-3.pt-2
                          car-icon.icon-2x.mr-3
                          p.pt-1.m-0 
                            | Drive - 
                            span.text-muted {{ item.distance }}
                        .d-flex
                          p.mb-0.mt-0.mr-auto.pr-3 {{ item.destination }}
                          span.text-muted {{ item.arrival_time }}
                      #timeline-line.timeline-line

    .row.mb-4
      .col-12.col-xl-6
        .card
          .card-body
            h4.card-title Total Jarak Tempuh Kendaraan (m)
            .date-picker-container.d-flex.justify-content-center.align-items-center.my-3
              a.text-secondary(href="javascript:;", @click="switchDate(globalDate, -1, 'globalDate')")
                chevron-left-icon.icon-2x
              datepicker.mx-2(input-class="form-control", @selected="onSwitchDate", :value="globalDate", ref="globalDate")
              a.text-secondary(href="javascript:;", @click="switchDate(globalDate, 1, 'globalDate')")
                chevron-right-icon.icon-2x
            .chart-container
              line-chart2(:chart-data="speedChart.data", :options="speedChart.options", :height="250", :stacked="true")
            .btn-group.float-right.mt-3
              button.btn.btn-sm.btn-switch(:class="{ active: speedType == 'hourly' }", @click="speedType = 'hourly'") 24h

      .col-12.col-xl-6
        .card
          .card-body
            h4.card-title Level Bahan Bakar Kendaraan (L)
            .date-picker-container.d-flex.justify-content-center.align-items-center.my-3
              a.text-secondary(href="javascript:;", @click="switchDate(globalDate, -1, 'globalDate')")
                chevron-left-icon.icon-2x
              datepicker.mx-2(input-class="form-control", @selected="onSwitchDate", :value="globalDate", ref="globalDate")
              a.text-secondary(href="javascript:;", @click="switchDate(globalDate, 1, 'globalDate')")
                chevron-right-icon.icon-2x
            .chart-container
              line-chart3(:chart-data="fuelChart.data", :options="fuelChart.options", :height="250", :stacked="true")
            .btn-group.float-right.mt-3
              button.btn.btn-sm.btn-switch(:class="{ active: fuelType == 'hourly' }", @click="fuelType = 'hourly'") 24h

    .row
      .col-12
        .card
          .card-body
            h4.card-title Total Jarak Tempuh Kendaraan & Level Bahan Bakar Kendaraan
            .date-picker-container.d-flex.justify-content-center.align-items-center.my-3
              a.text-secondary(href="javascript:;", @click="switchDate(globalDate, -1, 'globalDate')")
                chevron-left-icon.icon-2x
              datepicker.mx-2(input-class="form-control", @selected="onSwitchDate", :value="globalDate", ref="globalDate")
              a.text-secondary(href="javascript:;", @click="switchDate(globalDate, 1, 'globalDate')")
                chevron-right-icon.icon-2x
            .chart-container
              line-chart(:chart-data="RandomChart.data", :options="RandomChart.options", :height="500", :width="'1100'", :stacked="true")
            .btn-group.float-right.mt-3
              button.btn.btn-sm.btn-switch(:class="{ active: combinedType == 'hourly' }", @click="combinedType = 'hourly'") 24h
             

          .row
          .col-12
          .matador-tab.d-flex.vehicle-tab.px-5
            a.mr-5.text-center.py-3(:class="{'active': currentTab == 0}" @click="currentTab = 0" href="javascript:;") Informasi Pelacakan
          .row
            .py-2.px-5.col-6(v-for="column, index in tabs" :class="{'border-right' : index ===0}")
              .border-bottom(v-for="item in Object.values(column)")
                .d-flex.py-3.justify-content-between
                  p.font-weight-bolder.m-0.matador-text-02 {{ item[0] }}
                  p.m-0.text-right {{ item[1] }}
    
</template>
<script>
import BarChart from "@/components/Chartjs/BarChart";
import LineChart from "@/components/Chartjs/LineChart";
import LineChart2 from "@/components/Chartjs/LineChart2";
import { ChartCard } from "@/components/index";
import {
  getFuelConsumtion,
  getOperatingMile,
  getTrip,
  getVehicleDetail,
} from "@/utils/api";
import { LATITUDE_ASEAN, LONGITUDE_ASEAN, ZOOM_ASEAN } from "@/utils/map";
import dayjs from "dayjs";
import CarIcon from "vue-material-design-icons/Car.vue";
import ChevronLeftIcon from "vue-material-design-icons/ChevronLeft.vue";
import ChevronRightIcon from "vue-material-design-icons/ChevronRight.vue";
import Datepicker from "vuejs-datepicker";
import LineChart3 from "../../../components/Chartjs/LineChart3";

export default {
  data() {
    return {
      cols: 2,
      currentTab: 0,
      globalDate: null,
      tripDate: null,
      direction: null,
      speedDate: null,
      fuelDate: null,
      vehicle: null,
      map: null,
      speedType: "hourly",
      fuelType: "hourly",
      speedChartData: {
        label: [],
        total: [],
      },
      fuelChartData: {
        label: [],
        total: [],
      },
    };
  },
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    CarIcon,
    ChartCard,
    BarChart,
    LineChart,
    LineChart2,
    LineChart3,
    Datepicker,
  },
  watch: {
    globalDate: {
      handler: function (newVal) {
        this.tripDate = this.speedDate = this.fuelDate = newVal;
      },
      deep: true,
    },
    tripDate: {
      handler: function (newVal) {
        this.getVehicleDetail(newVal);
        this.getTripDetail(newVal);
      },
      deep: true,
    },
    speedDate: {
      handler: function (newVal) {
        this.getVehicleSpeed(newVal);
      },
      deep: true,
    },
    speedType: {
      handler: function (newVal) {
        this.getVehicleSpeed(this.speedDate);
      },
      deep: true,
    },
    fuelDate: {
      handler: function (newVal) {
        this.getFuelConsumtion(newVal);
      },
      deep: true,
    },
    fuelType: {
      handler: function (newVal) {
        this.getFuelConsumtion(this.fuelDate);
      },
      deep: true,
    },
    street: {
      handler: function (newVal) {
        var that = this;

        var checker = setInterval(function () {
          if (window.google) {
            that.renderMap(newVal);
            clearInterval(checker);
          }
        }, 1000);
      },
      deep: true,
    },
  },
  methods: {
    closeDatePicker(event) {
      // const className = (event.target.className || "");
      // console.log()
      // if(className && className.indexOf('btn up') == -1 && className.indexOf('next') == -1 && className.indexOf('prev') == -1){
      //   this.$refs.speedDatePicker.close();
      //   this.$refs.fuelDatePicker.close();
      // }
    },
    closeSpeedDatePicker() {
      this.$refs.speedDatePicker.close();
    },
    closeFuelDatePicker() {
      this.$refs.fuelDatePicker.close();
    },
    formatDistance(meters) {
      const km = meters / 1000;
      return `${km.toFixed(2)} Km`;
    },

    // Format seconds to hours and minutes
    formatOperatingTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);

      if (hours === 0) {
        return `${minutes} mnt`;
      }
      return `${hours} jam ${minutes} mnt`;
    },
    onSwitchDate(val) {
      this.globalDate = val;
    },
    setCurrentDateSpeed(value) {
      this.speedDate = value;
    },
    setCurrentDateFuel(value) {
      this.fuelDate = value;
    },
    showCalendar(ref) {
      setTimeout(this.$refs[ref].showCalendar, 100);
    },
    onSwitchDate(val) {
      this.globalDate = val;
    },
    switchDate(current, n, prop) {
      const result = dayjs(current).add(n, "days");
      this[prop] = result;

      if (prop === "fuelDate") {
        this.speedDate = result; // Update speedDate if fuelDate changes
        this.getFuelConsumtion(result);
        this.getVehicleSpeed(result);
      } else if (prop === "speedDate") {
        this.fuelDate = result; // Update fuelDate if speedDate changes
        this.getVehicleSpeed(result);
        this.getFuelConsumtion(result);
      }
    },
    onDateTyping(event) {
      event.preventDefault();
    },
    getVehicleDetail(date) {
      const trip = dayjs(date).format("YYYY-MM-DD");
      const { unit_id } = this.$route.params;
      getVehicleDetail({ unit_id, trip }).then(({ data: { result } }) => {
        this.vehicle = result;
      });
    },
    getTripDetail(date) {
      const trip = dayjs(date).format("YYYY-MM-DD");
      const { unit_id } = this.$route.params;
      getTrip({ unit_id, trip }).then(({ data: { result } }) => {
        this.direction = result;
        if (result && result.length > 0) {
          setTimeout(this.renderMap, 1000);
        }
      });
    },
    getVehicleSpeed(val) {
      const date = dayjs(val).format("YYYY-MM-DD");
      const { unit_id } = this.$route.params;
      const { speedType: type } = this;
      getOperatingMile({
        date,
        unit_id,
        type,
        category: "operate_mileage",
      }).then(({ data: { result } }) => {
        this.speedChartData.label = result.map((item) => item.label);
        this.speedChartData.total = result.map((item) => parseInt(item.total));
      });
    },
    getFuelConsumtion(val) {
      const date = dayjs(val).format("YYYY-MM-DD");
      const { unit_id } = this.$route.params;
      const { fuelType: type } = this;
      getFuelConsumtion({ date, unit_id, type }).then(
        ({ data: { result } }) => {
          this.fuelChartData.label = result.map((item) => item.label);
          this.fuelChartData.total = result.map((item) => parseInt(item.total));
        },
      );
    },

    renderMap(newVal) {
      if (!window.google) return;
      if (!this.map) {
        var initLatLng = new window.google.maps.LatLng(
          LATITUDE_ASEAN,
          LONGITUDE_ASEAN,
        );
        var mapOptions = {
          zoom: ZOOM_ASEAN,
          scrollwheel: false, // we disable de scroll over the map, it is a really annoing when you scroll through page
          mapTypeControl: true,
          scaleControl: true,
          streetViewControl: false,
          center: initLatLng,
          mapTypeControlOptions: {
            position: window.google.maps.ControlPosition.TOP_RIGHT,
          },
        };

        var map = new window.google.maps.Map(
          document.getElementById("mapDetail"),
          mapOptions,
        );
        this.map = map;
        this.map.setZoom(ZOOM_ASEAN);
        this.map.panTo(initLatLng);
      }
      // return;
      if (!newVal || newVal.length === 0) return;

      var marker, i;
      var bounds = new window.google.maps.LatLngBounds();
      let streets = [];
      for (i = 0; i < newVal.length; i++) {
        const locations = newVal[i];
        streets.push({ lat: locations.latitude, lng: locations.longitude });

        bounds.extend({ lat: locations.latitude, lng: locations.longitude });
      }
      const streetPath = new window.google.maps.Polyline({
        path: streets,
        geodesic: true,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      streetPath.setMap(this.map);

      this.map.fitBounds(bounds);
      this.setTimelineHeight();
    },
    setTimelineHeight() {
      var height = window.$(".trip-timeline")[0].scrollHeight;
      height = height - window.$(".timeline-section").last().outerHeight() + 15;
      window.$("#timeline-line").height(height);
    },
  },
  computed: {
    vehicle_data() {
      const { vehicle } = this;
      const device_unit = vehicle.device_unit;
      const log = device_unit.device.last_log_data || {};
      return [
        ["Nomor Polisi", vehicle.vin],
        ["Nama Perangkat", vehicle.device_unit.device.description],
        ["Jenis Kendaraan", vehicle.unit_type.vehicle_type.name],
      ];
    },
    globalDate: {
      get() {
        return this.tripDate;
      },
      set(newValue) {
        this.tripDate = newValue;
        this.speedDate = newValue;
        this.fuelDate = newValue;
      },
    },
    tabs() {
      if (!this.vehicle) return [];
      const log = this.vehicle.device_unit.device.last_log_data || {};
      let current_info = [];
      if (this.currentTab == 0) {
        current_info = [
          ["Nomor Polisi", this.vehicle.vin],
          [
            "Koordinat",
            log.latitude ? log.latitude + " , " + log.longitude : "-",
          ],
          ["Status Daya", log.power_status ? "On" : "Off"],
          /*  ["UUID", this.vehicle.device_unit.device_id], */
          ["Jarak Tempuh", this.formatDistance(log.total_distance2)],
          [
            "Waktu Operasional Hari Ini",
            this.formatOperatingTime(log.operating_time2),
          ],
          ["IMEI", log.imei ? log.imei : "-"],
          ["Nomor SIM", this.vehicle.device_unit.device.sim_number],
          ["Pemakaian Bahan Bakar", log.fuel_level2 + " L"],
          ["Rata-Rata Pemakaian Bahan Bakar", log.fuel_consumption2 + " km/L"],
          ["Waktu Mulai Siklus", log.siklus_start],
          ["Waktu Selesai Siklus", log.siklus_end],
        ];
        // console.log("current Info :",current_info)
      } else if (this.currentTab == 1) {
        current_info = [
          ["Voltmeter", log.voltage ? log.voltage : "-"],
          ["Start Stop Counter", log.start_count ? log.start_count : "-"],
          ["Engine", this.vehicle.unit_type.engine],
          ["Tire Count", this.vehicle.unit_type.tire_count],
          ["Torque", this.vehicle.unit_type.torque],
          [
            "Brand",
            this.vehicle.unit_type.brand_id
              ? this.vehicle.unit_type.brand_id
              : "-",
          ],
          ["Series", this.vehicle.unit_type.series],
          ["Year", this.vehicle.unit_type.year],
          ["Fuel", this.vehicle.unit_type.fuel],
        ];
      } else if (this.currentTab == 2) {
        current_info = [
          ["GSM Signal", log.signal ? log.signal : "-"],
          ["IMEI", log.imei ? log.imei : "-"],
          [
            "Carrier/Mobile Network",
            this.vehicle.device_unit.device.network_provider_id,
          ],
          ["SIM Number", this.vehicle.device_unit.device.sim_number],
          ["Description", this.vehicle.device_unit.device.description],
          ["Data Usage", log.data_usage ? log.data_usage + " MB" : "-"],
          [
            "Device Temperature",
            log.temperature ? log.temperature + " C" : "-",
          ],
        ];
      }

      let columns = [];
      let mid = Math.ceil(current_info.length / this.cols);
      for (let col = 0; col < this.cols; col++) {
        columns.push(current_info.slice(col * mid, col * mid + mid));
      }
      return columns;
    },
    trip() {
      if (!this.direction) {
        return [];
      }
      return this.direction.map((item) => {
        const { start, end, direction } = item;

        return {
          origin: start.location_name,
          departure_time: dayjs(start.timestamp).format("HH:mm"),
          distance: direction.distance,
          duration: direction.duration,
          destination: end.location_name,
          arrival_time: dayjs(end.timestamp).format("HH:mm"),
        };
      });
    },
    street() {
      // console.log(this.direction + " false");

      if (!this.direction) {
        return [];
      }

      // console.log(this.direction + " oke");

      var arrays = this.direction.map((item) => {
        const { start, end, direction } = item;

        return direction.trip;
      });
      return [].concat.apply([], arrays);
    },
    RandomChart() {
      const {
        fuelChartData: { label: fuelLabels, total: fuelTotals },
      } = this;
      const {
        speedChartData: { total: speedTotals, label: speedLabels },
      } = this;

      return {
        data: {
          labels: fuelLabels.map((item) => {
            if (
              typeof item === "string" &&
              !isNaN(parseInt(item)) &&
              item.length === 2
            ) {
              return item;
            } else {
              return dayjs(item).format("DD MMM YY");
            }
          }),
          datasets: [
            {
              label: "Bahan Bakar",
              data: fuelTotals.map((value) => value),
              borderColor: "#FFAC1C",
              lineTension: 0,
              backgroundColor: "transparent",
              borderWidth: 5,
              stepped : true,
              yAxisID: "y-axis-0", // menggunakan sumbu Y kiri
            },
            {
              label: "Jarak Kendaraan",
              data: speedTotals.map((value) => value),
              borderColor: "#7998B9",
              backgroundColor: "transparent",
              borderWidth: 5,
              lineTension: 0,
              stepped : true,
              yAxisID: "y-axis-1", // menggunakan sumbu Y kanan
            },
          ],
        },
        options: {
          stacked: true,
          tooltips: { enabled: true },
          hover: { mode: null },
          legend: {
            display: true,
            onClick: (e) => e.stopPropagation(),
          },
          scales: {
            yAxes: [
              {
                id: "y-axis-0",
                type: "linear",
                position: "left",
              },
              {
                id: "y-axis-1",
                type: "linear",
                position: "right",
              },
            ],
          },
        },
      };
    },
    fuelChart() {
      const {
        fuelChartData: { label, total },
      } = this;
      const threshold = 15;
      const totalFinal = total.map((value, index, array) => {
        // If this is the first or last element, return its value
        if (index === 0 || index === array.length - 1) {
          return value;
        }

        const prevValue = array[index - 1];
        const nextValue = array[index + 2];

        // Check if the current value is lower than the previous value
        if (value < prevValue) {
          // Check if the difference between the current value and the next value is less than the threshold
          if (nextValue - value < threshold) {
            // Replace the current value with the average of the previous and next values
            return Math.round((prevValue + nextValue) / 2);
          }
        }

        return value;
      });

      console.log(
        "fuelChartData : Isi dari total:",
        totalFinal.map((value) => value),
      );

      console.log(
        "fuelChartData : Isi dari label:",
        label.map((value) => value),
      );
      return {
        data: {
          labels: label.map((item) => {
            if (
              typeof item === "string" &&
              !isNaN(parseInt(item)) &&
              item.length === 2
            ) {
              return item;
            } else {
              return dayjs(item).format("DD MMM YY");
            }
          }),
          datasets: [
            {
              label: "Bahan Bakar",
              borderColor: "#FFAC1C",
              lineTension: 0,
              backgroundColor: "transparent",
              data: total.map((value) => value), // Mengubah ke liter
              beginAtZero: true,
              borderWidth: 5,
            },
          ],
        },
        options: {
          tooltips: { enabled: true },
          hover: { mode: null },
          legend: {
            display: false,
            text: 'Chart.js Line Chart',
            onClick: (e) => e.stopPropagation(),
          },
          scales: {
            x: {
              grid: {
                diplay: false,
              },
            },
          },
        },
      };
    },
    speedChart() {
      const {
        speedChartData: { label, total },
      } = this;
      return {
        data: {
          labels: label.map((item) => {
            if (
              typeof item === "string" &&
              !isNaN(parseInt(item)) &&
              item.length === 2
            ) {
              return item;
            } else {
              return dayjs(item).format("DD MMM YY");
            }
          }),
          datasets: [
            {
              label: "Jarak",
              borderColor: "#7998B9",
              lineTension: 0,
              backgroundColor: "transparent",
              data: total.map((value) => value), // Mengubah ke liter
              beginAtZero: true,
              stepped : true,
              borderWidth: 5,
            },
          ],
        },
        options: {
          stacked: true,
          tooltips: { enabled: true },
          hover: { mode: null },
          legend: {
            display: false,
            text: 'Chart.js Line Chart',
            onClick: (e) => e.stopPropagation(),
          },
          scales: {
            x: {
              grid: {
                diplay: false,
              },
            },
          },
        },
      };
    },
  },

  mounted() {
    this.globalDate = new Date();
    // this.globalDate = new Date('October 20, 2020');
  },
};
</script>
<style lang="scss">
@import "../../../assets/sass/paper/variables.scss";

.datepicker-container {
  z-index: 9999 !important;
}

.trip-container {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
  }
}

.trip-timeline {
  position: relative;
  width: 100%;

  // max-height: 276px;
  > .timeline-line {
    content: " ";
    position: absolute;
    width: 10px;
    background-color: $matador-blue;
    left: 1rem;
    top: 1.5rem;
    bottom: 4%;
    border-radius: 5px;
  }

  > section {
    position: relative;

    &::after {
      content: " ";
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background-color: #fff;
      left: 16px;
      top: 18px;
      z-index: 1000;
    }
  }
}

.vehicle-tab {
  border-bottom: none !important;
}
</style>
